/* You can add global styles to this file, and also import other style files */
html {
  /* clearing out the default background image and color in ife-foxhound.css */
  background-image: none !important;
  background-color: inherit !important;
  --ui-density: 1; /* default value */
}

.main-container {
  min-height: 500px;
  padding-bottom: 10px;
}

.mat-select-trigger {
  background-color: transparent !important;
}
.cdk-overlay-container {
  position: absolute !important;
  z-index: 10002 !important;
}
.mat-form-field-suffix {
  width: auto !important;
}

.mat-form-field-flex {
  padding: 0 !important;
}

[mat-icon-button] {
  border: none;
  background: transparent;
}

.mat-snack-bar-container {
  padding: 14px 16px !important;
}

.c-overview-details-grid__label {
  margin-bottom: 3px !important;
}

.c-input-group button {
  padding: 0 15px;
}

/* Remove space reserved for error message */
.mat-form-field .mat-form-field-wrapper {
  padding-bottom: 0.25em;
}

.mat-form-field-subscript-wrapper {
  top: calc(100% - 0.75em) !important;
}

.cursor-in-progress,
.cursor-in-progress input,
.cursor-in-progress textarea,
.cursor-in-progress button {
  cursor: wait !important;
}

.dropdown-option {
  padding: 6px 0;
}

.dropdown-option__email,
.dropdown-option__sub-info {
  font-size: 0.8em;
  opacity: 0.8;
}
.showWaiting{
  display: block !important;
}
.hideWaiting{
display: none;
}
